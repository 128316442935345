window.JobBoard = () => {
	return {
		jobs: [],
		searchType: 'freetext',
		searchText: '',
		searchTerm: '',
		filteredJobs: [],
		displayedJobs: [],
		page: 1,
		perPage: 10,
		sortKey: '',
		sortDesc: false,
		loading: true,
		allJobsLoaded: false,
		init() {
			this.fetchJobs();
		},
		fetchJobs() {
			this.page = 1;
			this.displayedJobs = [];
			this.loading = true;
			fetch(
				`${ajax.url}?action=get_jobs_eq&searchType=${this.searchType}&searchText=${this.searchText}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
				.then(response => response.json())
				.then(data => {
					this.jobs = data.data;
					this.displayedJobs = this.jobs.slice(0, this.perPage);
					this.sortJobs();
					this.loading = false;
					if (this.displayedJobs.length === this.jobs.length || this.jobs.length < this.perPage) {
						this.allJobsLoaded = true;
					}
				})
				.catch(error => {
					console.error('Error:', error);
					this.loading = false;
				});
		},
		sortJobs() {
			if (this.sortKey) {
				this.displayedJobs = this.displayedJobs.sort((a, b) => {
					let aValue = a[this.sortKey];
					let bValue = b[this.sortKey];
					if (this.sortKey === 'dateStart') {
						aValue = new Date(aValue);
						bValue = new Date(bValue);
					}
					if (this.sortDesc) {
						return aValue < bValue ? 1 : -1;
					}
					return aValue > bValue ? 1 : -1;
				});
			}
		},
		resetSearch() {
			this.searchType = 'freetext';
			this.searchText = '';
			this.searchTerm = '';
			this.fetchJobs();
			this.allJobsLoaded = false;
		},
		loadMore() {
			let jobSource = this.filteredJobs.length > 0 ? this.filteredJobs : this.jobs;
			this.displayedJobs = [
				...this.displayedJobs,
				...jobSource.slice(this.displayedJobs.length, this.displayedJobs.length + this.perPage),
			];
			if (this.displayedJobs.length === jobSource.length) {
				this.allJobsLoaded = true;
			}
		},

		filterData() {
			this.filteredJobs = this.jobs.filter(job =>
				job.title.toLowerCase().includes(this.searchTerm.toLowerCase())
			);
			this.displayedJobs = this.filteredJobs.slice(0, this.perPage);
			if (this.displayedJobs.length === this.jobs.length || this.jobs.length < this.perPage) {
				this.allJobsLoaded = true;
			} else {
				this.allJobsLoaded = false;
			}
		},

	};
};
