window.InteractiveMap = interactiveMapId => {
	return {
		headingHover: false,
		init() {
			this.$refs[`parentDiv-${interactiveMapId}`]
				.querySelectorAll('.interactiveMapHeader')
				.forEach(element => {
					element.addEventListener('mouseover', () => {
						this.headingHover = true;
					});
				});

			const interactiveMapElement = document.getElementById('interactiveMap');
			if (interactiveMapElement) {
				interactiveMapElement.addEventListener('mouseleave', () => {
					this.headingHover = false;
				});
			}
		},

		currentlyHoveredItem: '',
		handleMouseOver(city) {
			this.currentlyHoveredItem = city;
			const cityElement = document.getElementById(city);
			const parentDiv = this.$refs[`parentDiv-${interactiveMapId}`];

			parentDiv.style.position = 'relative';

			cityElement.style.display = 'block';

			this.$refs[`parentDiv-${interactiveMapId}`]
				.querySelectorAll('.interactiveMapText')
				.forEach(element => {
					element.classList.add('invisible');
				});
		},
		handleMouseLeave() {
			if (this.currentlyHoveredItem) {
				document.getElementById(this.currentlyHoveredItem).style.display = 'none';
			}
			this.currentlyHoveredItem = '';
			this.$refs[`parentDiv-${interactiveMapId}`]
				.querySelectorAll('.interactiveMapText')
				.forEach(element => {
					element.classList.remove('invisible');
				});
		},
		close() {
			this.headingHover = false;
		}
	};
};
