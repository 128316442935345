window.SecondaryHero = function (blockId) {
	return {
		hover: false,
		offsetTop: 0,
		learnMoreHover() {
			this.hover = true;
		},
		learnMoreLeave() {
			this.hover = false;
		},
		init() {
			this.setOffsetTop();
		},
		setOffsetTop() {
			requestAnimationFrame(() => {
				const descRect = this.$refs[`desc-${blockId}`].getBoundingClientRect();
				const containerboxRect = this.$refs[`containerbox-${blockId}`].getBoundingClientRect();
				const hoverboxRect = this.$refs[`hoverbox-${blockId}`].getBoundingClientRect();

				this.offsetTop = descRect.top - containerboxRect.top;

				// check if hoverText would render outside the parent
				if (this.offsetTop + hoverboxRect.height > containerboxRect.height) {
					// adjust offsetTop to align the bottom of hoverText with the bottom of the parent
					this.offsetTop = containerboxRect.height - (hoverboxRect.height + 20);
				}
			});
		},
	};
};
